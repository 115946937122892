export default [
    {index:1 ,value:"01:00 AM", hours:"01:00" },
    {index:2 ,value:"02:00 AM", hours:"02:00" },
    {index:3 ,value:"03:00 AM", hours:"03:00" },
    {index:4 ,value:"04:00 AM", hours:"04:00" },
    {index:5 ,value:"05:00 AM", hours:"05:00" },
    {index:6 ,value:"06:00 AM", hours:"06:00" },
    {index:7 ,value:"07:00 AM", hours:"07:00" },
    {index:8 ,value:"08:00 AM", hours:"08:00" },
    {index:9 ,value:"09:00 AM", hours:"09:00" },
    {index:10 ,value:"10:00 AM", hours:"10:00" },
    {index:11 ,value:"11:00 AM", hours:"11:00" },
    {index:12 ,value:"12:00 AM", hours:"12:00" },
    {index:13 ,value:"01:00 PM", hours:"13:00" },
    {index:14 ,value:"02:00 PM", hours:"14:00" },
    {index:15 ,value:"03:00 PM", hours:"15:00" },
    {index:16 ,value:"04:00 PM", hours:"16:00" },
    {index:17 ,value:"05:00 PM", hours:"17:00" },
    {index:18 ,value:"06:00 PM", hours:"18:00" },
    {index:19 ,value:"07:00 PM", hours:"19:00" },
    {index:20 ,value:"08:00 PM", hours:"20:00" },
    {index:21 ,value:"09:00 PM", hours:"21:00" },
    {index:22 ,value:"10:00 PM", hours:"22:00" },
    {index:23 ,value:"11:00 PM", hours:"23:00" },
    {index:24 ,value:"00:00 PM", hours:"00:00" },

]