<template>
  <!-- Begin Mailchimp Signup Form -->
  <div id="mc_embed_signup" class="row-start-2 col-start-1 col-end-4 border-b  text-white">
    <form action="https://espaciotemporal.us14.list-manage.com/subscribe/post?u=d2409e2d45bdf2c9f4638d6a1&amp;id=28a446ba3c&amp;f_id=000987e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
      <div id="mc_embed_signup_scroll" class="grid grid-cols-1 lg:grid-cols-3">

        <div class="">
          <div class="mc-field-group">
            <label for="mce-EMAIL">Email  <span class="asterisk">*</span></label>
            <input type="email" value="" name="EMAIL" class="required email text-black" id="mce-EMAIL" required>
            <!-- <span id="mce-EMAIL-HELPERTEXT" class="helper_text text-white"></span> -->
          </div>
          <div class="mc-field-group">
            <label for="mce-FNAME">Nombre  <span class="asterisk">*</span></label>
            <input type="text" value="" name="FNAME" class="required text-black" id="mce-FNAME" required>
            <!-- <span id="mce-FNAME-HELPERTEXT" class="helper_text text-white"></span> -->
          </div>
        </div>

        <div class="mc-field-group">
          <label for="mce-MSG">Mensaje </label>
          <textarea cols="30" rows="7" value="" name="MSG" class=" border-2 border-gray-400 rounded text-black" id="mce-MSG"></textarea>
          <!-- <input type="text" value="" name="MSG" class="" id="mce-MSG"> -->
          <!-- <span id="mce-MSG-HELPERTEXT" class="helper_text text-white"></span> -->
        </div>
        
        <div class="flex justify-center items-center">
          
          <div class="optionalParent">
            <div class="clear foot">
              <input type="submit" value="Subscribirme" name="subscribe" id="mc-embedded-subscribe" class="button">
              <!-- <p class="brandingLogo"><a href="http://eepurl.com/h0TLKz" title="Mailchimp - email marketing made easy and fun"><img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"></a></p> -->
            </div>
          </div>

          <div id="mce-responses" class="clear foot">
            <div class="response" id="mce-error-response" style="display:none"></div>
            <div class="response" id="mce-success-response" style="display:none"></div>
          </div>

          <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_46f607e6d3e0284d209037201_a834f84dce" tabindex="-1" value=""></div>
          
        </div>
      </div>
    </form>
  </div>
  <!--End mc_embed_signup-->
</template>

<script>
export default {

}
</script>

<style>

</style>