var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PartTemplateVue',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h3',{staticClass:"font-black text-2xl my-5"},[_vm._v(" "+_vm._s(_vm.$t("tenants.calendar.Paso2"))+" ")])]},proxy:true},{key:"button",fn:function(){return [_c('button',{staticClass:"my-btn w-auto rounded-full py-1 px-5 bg-gray-500 mr-4",on:{"click":function($event){return _vm.$emit('navigate', 'back')}}},[_vm._v(" "+_vm._s(_vm.$t("general.previus"))+" ")]),_c('button',{staticClass:"my-btn w-auto rounded-full py-1 px-5",class:_vm.formData.signature===''&&'bg-opacity-50',attrs:{"disabled":_vm.formData.signature===''},on:{"click":_vm.goNextPart}},[_vm._v(" "+_vm._s(_vm.$t("general.next"))+" ")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"p-4"},[_c('p',{staticClass:"font-semibold cursor-pointer mb-10",on:{"click":_vm.seePDF}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"file-contract"}}),_vm._v(" "+_vm._s(_vm.$t("tenants.calendar.see"))+" ")],1),_c('button',{staticClass:"my-btn py-1 px-2 w-auto rounded-lg mb-4",on:{"click":function($event){_vm.showModal = true}}},[_vm._v(_vm._s(_vm.$t('tenants.calendar.changeContract')))]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.signature),expression:"formData.signature"}],staticClass:"my-input bg-white border-b border-black signature w-full uppercase",attrs:{"type":"text","placeholder":_vm.$t("tenants.calendar.form.signature")},domProps:{"value":(_vm.formData.signature)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "signature", $event.target.value)}}})])]),_c('ModelGlobal',{attrs:{"showModal":_vm.showModal}},[_c('div',{staticClass:"p-8 bg-white rounded-xl max-h-screen overflow-y-scroll"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"grid grid-cols-2  gap-y-4 gap-x-8"},[_c('ValidationProvider',{staticClass:"relative",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block"},[_vm._v(_vm._s(_vm.$t("tenants.calendar.form.company")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.company),expression:"formData.company"}],staticClass:"my-input bg-gray-300 w-full",attrs:{"type":"text"},domProps:{"value":(_vm.formData.company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "company", $event.target.value)}}}),_c('span',{staticClass:"my-error block relative top-0 left-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"relative",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block"},[_vm._v(_vm._s(_vm.$t("tenants.calendar.form.identity")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.identity),expression:"formData.identity"}],staticClass:"my-input bg-gray-300 w-full",attrs:{"type":"text"},domProps:{"value":(_vm.formData.identity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "identity", $event.target.value)}}}),_c('span',{staticClass:"my-error block relative top-0 left-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"relative",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block"},[_vm._v(_vm._s(_vm.$t("tenants.calendar.form.location_uses")))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.location_uses),expression:"formData.location_uses"}],staticClass:"my-input bg-gray-300 w-full h-20",domProps:{"value":(_vm.formData.location_uses)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "location_uses", $event.target.value)}}}),_c('span',{staticClass:"my-error block relative top-0 left-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"relative",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block"},[_vm._v(_vm._s(_vm.$t("tenants.calendar.form.people_fix")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.people_fix),expression:"formData.people_fix"}],staticClass:"my-input bg-gray-300 w-full",attrs:{"type":"number","min":"0"},domProps:{"value":(_vm.formData.people_fix)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "people_fix", $event.target.value)}}}),_c('span',{staticClass:"my-error block relative top-0 left-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"relative",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block"},[_vm._v(_vm._s(_vm.$t("tenants.calendar.form.people_prox")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.people_prox),expression:"formData.people_prox"}],staticClass:"my-input bg-gray-300 w-full",attrs:{"type":"number","min":"0"},domProps:{"value":(_vm.formData.people_prox)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "people_prox", $event.target.value)}}}),_c('span',{staticClass:"my-error block relative top-0 left-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"relative",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block"},[_vm._v(_vm._s(_vm.$t("tenants.calendar.form.represent")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.represent),expression:"formData.represent"}],staticClass:"my-input bg-gray-300 w-full",attrs:{"type":"text"},domProps:{"value":(_vm.formData.represent)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "represent", $event.target.value)}}}),_c('span',{staticClass:"my-error block relative top-0 left-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"relative",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block"},[_vm._v(_vm._s(_vm.$t("tenants.calendar.form.rut")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.rut),expression:"formData.rut"}],staticClass:"my-input bg-gray-300 w-full",attrs:{"type":"text"},domProps:{"value":(_vm.formData.rut)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "rut", $event.target.value)}}}),_c('span',{staticClass:"my-error block relative top-0 left-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"relative",attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block"},[_vm._v(_vm._s(_vm.$t("tenants.calendar.form.subContractor_email_1")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.subContractor_email_1),expression:"formData.subContractor_email_1"}],staticClass:"my-input bg-gray-300 w-full",attrs:{"type":"text"},domProps:{"value":(_vm.formData.subContractor_email_1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "subContractor_email_1", $event.target.value)}}}),_c('span',{staticClass:"my-error block relative top-0 left-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"relative",attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block"},[_vm._v(_vm._s(_vm.$t("tenants.calendar.form.subContractor_email_2")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.subContractor_email_2),expression:"formData.subContractor_email_2"}],staticClass:"my-input bg-gray-300 w-full",attrs:{"type":"text"},domProps:{"value":(_vm.formData.subContractor_email_2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "subContractor_email_2", $event.target.value)}}}),_c('span',{staticClass:"my-error block relative top-0 left-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"flex gap-2 justify-end"},[_c('button',{staticClass:"my-btn w-auto px-2 py-1 rounded-md bg-red-600",attrs:{"type":"submit"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c('button',{staticClass:"my-btn w-auto px-2 py-1 rounded-md",class:invalid ? ' bg-opacity-70' : '',attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.didExtraDataChanged ?_vm.$t("general.update") :_vm.$t("general.continue"))+" ")])])])]}}])})],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }