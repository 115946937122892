// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import "firebase/compat/auth";
import { getStorage, uploadBytes, listAll, ref, getDownloadURL,deleteObject } from "firebase/storage";
import {
  getAuth,
  signInWithCustomToken,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

import {
  getAnalytics,
  setUserProperties,
  setUserId,
  setCurrentScreen,
  logEvent,
  setAnalyticsCollectionEnabled,
} from "firebase/analytics";
import Vue from "vue";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const storage = getStorage(firebaseApp);
const auth = getAuth();
const analytics = getAnalytics();

Vue.prototype.$analytics = analytics;
setAnalyticsCollectionEnabled(analytics,false)

export const uploadBytesfunciont = uploadBytes;
export {
  storage,
  auth,
  signInWithCustomToken,
  firebaseApp as default,
  onAuthStateChanged,
  signOut,
  analytics,
  setUserProperties,
  setAnalyticsCollectionEnabled,
  setUserId,
  setCurrentScreen,
  logEvent,
  listAll,
  ref,
  getDownloadURL,
  deleteObject,
};
