import { render, staticRenderFns } from "./Spiner.vue?vue&type=template&id=175e430c&scoped=true&"
import script from "./Spiner.vue?vue&type=script&lang=js&"
export * from "./Spiner.vue?vue&type=script&lang=js&"
import style0 from "./Spiner.vue?vue&type=style&index=0&id=175e430c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "175e430c",
  null
  
)

export default component.exports