import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserSecret,
  faBars,
  faRightLong,
  faSearch,
  faTimes,
  faCheck,
  faSpinner,
  faTrashCan,
  faFloppyDisk,
  faQuestionCircle,
  faInfoCircle,
  faMinus,
  faPlus,
  faPanorama,
  faCircleCheck,
  faTimesCircle,
  faUndo,
  faPhone,
  faUserCheck,
  faStar,
  faCamera,
  faUserPen,
  faMapLocationDot,
  faHouse,
  faTrash,
  faPen,
  faEye,
  faBed,
  faEyeSlash,
  faArrowRotateLeft,
  faDoorOpen,
  faRestroom,
  faTrainSubway,
  faCaretDown,
  faFileContract,
  faImages,
  faGear,
  faDollarSign
} from "@fortawesome/free-solid-svg-icons";
import {
  faEnvelope,
  faUser,
  faFileExcel,
  faCalendarDays,
  faFile,
  faFilePdf
} from "@fortawesome/free-regular-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faUserSecret,
  faBars,
  faRightLong,
  faSearch,
  faTimes,
  faUser,
  faCheck,
  faSpinner,
  faTrashCan,
  faFloppyDisk,
  faQuestionCircle,
  faInfoCircle,
  faMinus,
  faPlus,
  faPanorama,
  faCircleCheck,
  faTimesCircle,
  faUndo,
  faEnvelope,
  faPhone,
  faUserCheck,
  faStar,
  faCamera,
  faUserPen,
  faMapLocationDot,
  faHouse,
  faTrash,
  faPen,
  faEye,
  faBed,
  faEyeSlash,
  faFileExcel,
  faArrowRotateLeft,
  faDoorOpen,
  faRestroom,
  faTrainSubway, 
  faCaretDown,
  faFileContract,
  faWhatsapp,
  faImages,
  faGear,
  faDollarSign,
  faCalendarDays,
  faFile,
  faFilePdf,
);

Vue.config.productionTip = false;

export default FontAwesomeIcon;
