export default () => ({
  showLoginModal: false,
  showWelcomeModal: false,
  user: null,
  isAuth: false,
  tokenLastUpdated:null,
  token: null,
  firebaseStoratgeToken: null,
  siteCountry: "",

  showCookiesBanner: true,
});
