<template>
  <div class="w-full h-28 overflow-hidden flex justify-center items-center">
    <div class="loader" :style="cssVars">Loading...</div>
  </div>
</template>

<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: "#ffffff",
    },
    spinerColor: {
      type: String,
      default: "#1d4ed8",
    },
  },
  computed: {
    cssVars() {
      return {
        "--bg-color": this.bgColor,
        "--spiner-color": this.spinerColor,
      };
    },
  },
};
</script>

<style scoped>
.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: var(--spiner-color);
  background: -moz-linear-gradient(
    left,
    var(--spiner-color) 10%,
    rgba(29, 78, 216, 0) 42%
  );
  background: -webkit-linear-gradient(
    left,
    var(--spiner-color) 10%,
    rgba(29, 78, 216, 0) 42%
  );
  background: -o-linear-gradient(
    left,
    var(--spiner-color) 10%,
    rgba(29, 78, 216, 0) 42%
  );
  background: -ms-linear-gradient(
    left,
    var(--spiner-color) 10%,
    rgba(29, 78, 216, 0) 42%
  );
  background: linear-gradient(
    to right,
    var(--spiner-color) 10%,
    rgba(29, 78, 216, 0) 42%
  );
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: var(--spiner-color);
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.loader:after {
  background: var(--bg-color);
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
